/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import "./styles/EditChaptersOrEpisodes.scss";
import AppContext from "../../../context/appContext";
import UploadedDramaturgy from "../../../components/UploadedFile/UploadInputs/UploadInputDramaturgy";
import { compileOptions } from "../compileOptions";
import { putService } from "../../../services/script-api";
import FormAndFields from "../CreateAndUpdateBlockPlanning/FormAndFields/FormAndFields";

const EditChaptersOrEpisodes = (props) => {
  const { file, listItems, reload, isChapter, isMaterial } = props;
  
  const appContext = useContext(AppContext);

  const {
    id,
    version,
    receivedAt,
    attachmentType,
    release,
    comment,
    releaseDate,
    blockNumber,
    chapterNumber,
    reason,
    structuringReview,
    attachedContentSight,
    attachedProdutionSight,
    attachedArtisticApproval,
    attachedChannelFeedback,
  } = file;

  const [stateEdit, setStateEdit] = useState({
    id,
    version,
    receivedAt,
    attachmentTypeId: attachmentType?.id,
    release,
    comment,
    releaseDate,
    blockNumber,
    chapterNumber,
    reason,
    structuringReview ,
    attachedContentSight: attachedContentSight ? new Date(attachedContentSight) : "",
    attachedProdutionSight: attachedProdutionSight ? new Date(attachedProdutionSight) : "",
    attachedArtisticApproval: attachedArtisticApproval ? new Date(attachedArtisticApproval) : "",
    attachedChannelFeedback: attachedChannelFeedback ? new Date(attachedChannelFeedback) : "",
  });

  const planningData = {
    entity:'Capítulo'
  }
  const handleStartDates = {
    attach: {
      attachedContentSight: "",
      attachedProdutionSight: "",
      attachedArtisticApproval: "",
      attachedChannelFeedback: "",
    },
  };
   
   
  const handleMaxDates = {
    attach: {
      attachedContentSight: "",
      attachedProdutionSight: "",
      attachedArtisticApproval: "",
      attachedChannelFeedback: "",
    },
  };

  const dramaturgyList = compileOptions(listItems);
  const action = {
    type: 'attach',
    data: null
  }

  const getDate = (date) => {
    if (date)
      setStateEdit({
        ...stateEdit,
        receivedAt: date,
      });
  };

  const handleChangeDate = (date, type) => {
    setStateEdit({
      ...stateEdit,
      [type]: date,
    });
  };

  const [notDefault, setNotDefault] = useState(false);
  const setStructuringReview =(has) =>{
    setNotDefault(true)
    setStateEdit({
      ...stateEdit,
      structuringReview: has,
    });
  }

  const getReason = (ev) => {
  const currentStructuringReview = stateEdit.structuringReview;

  if (currentStructuringReview) {
    setStateEdit({ ...stateEdit, reason: ev?.target?.value });
  } else {
    setStateEdit({ ...stateEdit, reason: null });
  }
};
 
  const getVersion = (version) => {
    setStateEdit({ ...stateEdit, version: version });
  };

  const getChapterAndBlock = (ev) => {
    const chapterId = ev.target.value;
    const chapter = dramaturgyList.find(
      (chapter) => chapter?.id === Number(chapterId)
    );

    setStateEdit({
      ...stateEdit,
      chapterNumber: chapter?.chaption,
      blockNumber: chapter?.block,
      id: Number(chapterId),
    });
  };

  const editFilesIsValid = () => {
    if (stateEdit.chapterNumber > 0){
      if(stateEdit.structuringReview && stateEdit.reason !== 'placeholder' && stateEdit.reason){
        return true
      }
      if(!stateEdit.structuringReview){
        return true
      }
      return false
    }
  }

  const putEditFile = (ev) => {
    ev.preventDefault();
    appContext.setShowGlassLoading(true);
    putService("contents/attachments", {
      ...stateEdit,
      id: file.id,
      receivedAt: appContext.transformDateToUTC(new Date(stateEdit.receivedAt)),
    })
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Arquivo editado com sucesso!");
        appContext.setCurrentStepBox("closed");
        reload();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao editar o arquivo!", "error");
        appContext.setCurrentStepBox("closed");
      });
  };

  React.useEffect(() => {
    if (notDefault && !stateEdit.structuringReview) {
      setStateEdit({ ...stateEdit, reason: null });
      setNotDefault(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEdit]); 

  return (
    <form className="editChaptersOrEpisodes">
      <UploadedDramaturgy
        state={stateEdit}
        dramaturgyList={dramaturgyList}
        getDate={getDate}
        getVersion={getVersion}
        getChapterAndBlock={getChapterAndBlock}
        getReason={getReason}
        setStructuringReview={setStructuringReview}
        isChapter={isChapter}
        isMaterial={isMaterial}
        {...props}
      />
      <div className="attachDates">
      <FormAndFields
      customFooter={true}
      action={action}
      handleChangeDate={handleChangeDate}
      handleSubmit={putEditFile}
      values={stateEdit}
      handleValidate={editFilesIsValid}
      handleStartDates={handleStartDates}
      handleMaxDates={handleMaxDates}
      chapterOrEpisode={null}
      planningData={planningData}
      isChapter={isChapter}
      />
      </div>
    </form>
  );
};

export default EditChaptersOrEpisodes;
