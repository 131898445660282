import React from "react";
import "../styles/UploadedFile.scss";
import CustomDatePicker from "../../CustomDatePicker/CustomDatepicker";
import NumberInput from "../../NumberInput/NumberInput";
import FieldsetSelect from "../../FieldsetSelect/FieldsetSelect";
import Switch from "../../Switch/Switch";

const UploadedDramaturgy = (props) => {
  const {
    thesaurusFileType,
    dramaturgyList,
    getChapterAndBlock,
    getAttachmentType,
    getReason,
    setStructuringReview,
    state,
    getVersion,
    getDate,
    darkBackgroundColor = true,
    isChapter = true,
    isMaterial,
  } = props;

  const reasons = [
    'Alteração de Número de Episódios',
    'Solicitação do Canal',
    'Solicitação da Produção',
    'Solicitação do Conteúdo'
  ]
  const newDate = state.receivedAt ? new Date(state.receivedAt) : new Date();

  return (
    <div className="containerFileInfo">
      <div className={`fileInfo ${!thesaurusFileType ? "onlyDatepicker" : ""}`}>
        {isMaterial && (
          <FieldsetSelect
          data={[
            { id: 1209, name: "Argumento" },
            { id: 1210, name: "Escaleta" },
            { id: 18738, name: "Roteiro" }
          ]}
          label={`Tipo do material`}
          placeholder={`Selecione`}
          name="name"
          handleChange={getAttachmentType}
          selected={state.attachmentTypeId}
          darkBackgroundColor={darkBackgroundColor}
          />
        )}

        {!isMaterial && (
          <FieldsetSelect
          data={dramaturgyList || []}
          label={`Nº do ${isChapter ? 'Capítulo' : 'Episódio'}`}
          placeholder={`Selecione um ${isChapter ? 'Capítulo' : 'Episódio'}`}
          name="name"
          handleChange={getChapterAndBlock}
          selected={state.id}
          darkBackgroundColor={darkBackgroundColor}
          />
        )}

        {!!thesaurusFileType && (
          <NumberInput
            label="Versão"
            getVersion={getVersion}
            darkBackgroundColor={darkBackgroundColor}
            number={state.version}
          />
        )}

        <CustomDatePicker
          label="Entregue em"
          name="attachmentReceivedAt"
          darkBackgroundColor={darkBackgroundColor}
          handleChangeDate={getDate}
          maxDate={new Date()}
          date={(e) => (!!e ? e : newDate)}
        />
      </div>
      <div className='switch'>

        {!isChapter && <Switch
          switchOnText={
            'Revisão Estruturante'
          }
          replaceYesAndNo={true}
          event={() => setStructuringReview(state.structuringReview ? false : true)}
          switchOn={state.structuringReview}
        />}
      </div>
      {state.structuringReview ?
        <div className={`fileInfo ${!thesaurusFileType ? "onlyDatepicker" : ""}`}>
          <FieldsetSelect
            custom={true}
            data={reasons || []}
            label="Motivo"
            placeholder="Selecione um Motivo"
            name="name"
            handleChange={getReason}
            selected={state.reason}
            darkBackgroundColor={darkBackgroundColor}
          />
        </div>
        :
        null
      }
    </div>
  );
};

export default UploadedDramaturgy;
