import React, { useState, useContext } from "react";
import "./styles/TableItem.scss";
import AppContext from "../../../../context/appContext";
import TypeCell from "../TypeCell/TypeCell";
import NoAttachments from "../NoAttachments/NoAttachments";
import MaterialsList from "../MaterialList/MaterialsList";
import TableItemDates from "../TableItemDates/TableItemDates";
import TableItemDatesReleasedText from "../TableItemDatesReleasedText/TableItemDatesReleasedText";
import ReleasedListAttachmentsController from "../../../ReleasedTexts/ReleasedListAttachments/ReleasedListAttachmentsController";

const TableItemMaterial = (props) => {
  const {
    items,
    mainCellTitle,
    typeValues,
    isMaterial,
    handleFileDetails,
    handleEditFile,
    handleOpenReleaseTexts,
    isChapter,
    type,
    isReleased,
    getPlanning,
    handleDeleteMaterial,
    setStateAction,
    projectTitle,
  } = props;

  const [stateIsOpen, setStateIsOpen] = useState(false);

  const appContext = useContext(AppContext);

  const handleDate = (date) => {
    if (date) return `${appContext.convertDatePtBr(date, "AA")}`;
    return <span className="datePlaceholder">{"-"}</span>;
  };

  const toggleIsOpen = () => setStateIsOpen((prevState) => !prevState);

  const sortedItems = items?.sort((a, b) => b.version - a.version);

  const hasItems = sortedItems && sortedItems.length > 0;

  const firstItemDates = hasItems
    ? {
        scheduleDelivery: handleDate(sortedItems[0].scheduleDelivery),
        rescheduledDelivery: handleDate(sortedItems[0].rescheduledDelivery),
        lastAttachmentReceiveDate: handleDate(sortedItems[0].lastAttachmentReceiveDate),
        scheduleRelease: handleDate(sortedItems[0].scheduleRelease),
        rescheduledRelease: handleDate(sortedItems[0].rescheduledRelease),
        lastAttachmentReleaseDate: handleDate(sortedItems[0].lastAttachmentReleaseDate),
        plannedChannelFeedback: handleDate(sortedItems[0].plannedChannelFeedback),
        plannedContentSight: handleDate(sortedItems[0].plannedContentSight),
        plannedProdutionSight: handleDate(sortedItems[0].plannedProdutionSight),
        plannedArtisticApproval: handleDate(sortedItems[0].plannedArtisticApproval),
        realizedArtisticApproval: handleDate(sortedItems[0].realizedArtisticApproval),
        plannedAuthorFeedback: handleDate(sortedItems[0].plannedAuthorFeedback),
        realizedAuthorFeedback: handleDate(sortedItems[0].realizedAuthorFeedback),
        realizedChannelFeedback: handleDate(sortedItems[0].realizedChannelFeedback),
        realizedContentSight: handleDate(sortedItems[0].realizedContentSight),
        realizedProdutionSight: handleDate(sortedItems[0].realizedProdutionSight),
      }
    : null;

  return (
    <li className={`blockListItem ${stateIsOpen ? "open" : ""}`}>
      <div className="blockListItemHeaderNotClick">
        <div className="blockListHeader">
          <div className="blockListItemHeader" onClick={toggleIsOpen}>
            <TypeCell
              isChapter={isChapter}
              isMaterial={isMaterial}
              mainCellTitle={mainCellTitle}
            />
            {hasItems && firstItemDates && (
              isReleased ? (
                <TableItemDatesReleasedText
                  scheduleDelivery={firstItemDates.scheduleDelivery}
                  rescheduledDelivery={firstItemDates.rescheduledDelivery}
                  lastAttachmentReceiveDate={firstItemDates.lastAttachmentReceiveDate}
                  scheduleRelease={firstItemDates.scheduleRelease}
                  rescheduledRelease={firstItemDates.rescheduledRelease}
                  lastAttachmentReleaseDate={firstItemDates.lastAttachmentReleaseDate}
                  type={type}
                />
              ) : (
                !stateIsOpen && (
                  <TableItemDates
                    handleDate={handleDate}
                    dates={firstItemDates}
                    type={type}
                    isChapter={isChapter}
                  />
                )
              )
            )}
          </div>
        </div>
      </div>

      <div className="blockListItemContent">
        {!hasItems ? (
          <NoAttachments message={typeValues.messages.noAttachmentsMessage} />
        ) : (
          sortedItems.map((item, index) => {
            const listValidate = item.attachments.filter(
              (file) => !!file.attachment.fileId
            );

            if (type === "channels" && !!listValidate?.length) {
              return (
                <React.Fragment key={`channel-item-${index}`}>
                  <ReleasedListAttachmentsController
                    files={listValidate}
                    title={projectTitle}
                    type={type}
                    isChapter={isChapter}
                    isMaterial={isMaterial}
                  />
                </React.Fragment>
              );
            }

            if (type === "channels" && !listValidate?.length) {
              return <NoAttachments message={typeValues.messages.noLinkedFileMessage} />
            }

            const dates = {
              scheduleDelivery: handleDate(item.scheduleDelivery),
              lastAttachmentReceiveDate: handleDate(item.lastAttachmentReceiveDate),
              scheduleRelease: handleDate(item.scheduleRelease),
              lastAttachmentReleaseDate: handleDate(item.lastAttachmentReleaseDate),
              plannedArtisticApproval: handleDate(item.plannedArtisticApproval),
              realizedArtisticApproval: handleDate(item.realizedArtisticApproval),
              plannedAuthorFeedback: handleDate(item.plannedAuthorFeedback),
              realizedAuthorFeedback: handleDate(item.realizedAuthorFeedback),
              plannedChannelFeedback: handleDate(item.plannedChannelFeedback),
              realizedChannelFeedback: handleDate(item.realizedChannelFeedback),
              plannedContentSight: handleDate(item.plannedContentSight),
              realizedContentSight: handleDate(item.realizedContentSight),
              plannedProdutionSight: handleDate(item.plannedProdutionSight),
              realizedProdutionSight: handleDate(item.realizedProdutionSight),
              rescheduledDelivery: handleDate(item.rescheduledDelivery),
              rescheduledRelease: handleDate(item.rescheduledRelease),
            };

            return (
              <React.Fragment key={`item-${index}`}>
                <MaterialsList
                  list={item}
                  handleDate={handleDate}
                  isLastItem={index === 0}
                  handleFileDetails={handleFileDetails}
                  handleEditFile={handleEditFile}
                  handleOpenReleaseTexts={handleOpenReleaseTexts}
                  dates={dates}
                  type={type}
                  isChapter={isChapter}
                  getPlanning={getPlanning}
                  isReleased={isReleased}
                  handleDeleteMaterial={handleDeleteMaterial}
                  setStateAction={setStateAction}
                />
              </React.Fragment>
            );
          })
        )}
      </div>
    </li>
  );
};

export default TableItemMaterial;
