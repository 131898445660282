import React from "react";
import "../styles/UploadedFile.scss";
import CustomDatePicker from "../../CustomDatePicker/CustomDatepicker";
import FieldsetSelect from "../../FieldsetSelect/FieldsetSelect";
import Switch from "../../Switch/Switch";

const UploadedMovie = (props) => {
  const {
    thesaurusFileType,
    getMaterialVersion,
    getAttachmentType,
    getReason,
    setStructuringReview,
    state,
    getDate,
    darkBackgroundColor = true,
    materialsList,
    isMaterial,
  } = props;

  const reasons = [
    "Alteração de Número de Episódios",
    "Solicitação do Canal",
    "Solicitação da Produção",
    "Solicitação do Conteúdo",
  ];

  const newDate = state.receivedAt ? new Date(state.receivedAt) : new Date();

  return (
    <div className="containerFileInfo">
      <div className={`fileInfoMaterial ${!thesaurusFileType ? "onlyDatepicker" : ""}`}>
        {isMaterial && (
          <FieldsetSelect
            data={Object.keys(materialsList).map((id) => ({
              id: Number(id),
              name:
                id === "1209"
                  ? "Argumento"
                  : id === "1210"
                  ? "Escaleta"
                  : id === "18738"
                  ? "Roteiro"
                  : "Desconhecido",
            }))}
            label="Tipo do material"
            placeholder="Selecione"
            handleChange={getAttachmentType}
            selected={state.attachmentTypeId}
            darkBackgroundColor={darkBackgroundColor}
          />
        )}

        <FieldsetSelect
          data={
            materialsList[state.attachmentTypeId]?.map(({ version }) => ({
              value: version,
            })) || []
          }
          label="Versão do Material"
          placeholder="Selecione"
          handleChange={getMaterialVersion}
          selected={state.version}
          darkBackgroundColor={darkBackgroundColor}
        />

        <CustomDatePicker
          label="Entregue em"
          name="attachmentReceivedAt"
          darkBackgroundColor={darkBackgroundColor}
          handleChangeDate={getDate}
          maxDate={new Date()}
          date={(e) => (!!e ? e : newDate)}
        />
      </div>

      <div className="switch">
        {isMaterial && (
          <Switch
            switchOnText="Revisão Estruturante"
            replaceYesAndNo={true}
            event={() => setStructuringReview(!state.structuringReview)}
            switchOn={state.structuringReview}
          />
        )}
      </div>

      {state.structuringReview && (
        <div className={`fileInfo ${!thesaurusFileType ? "onlyDatepicker" : ""}`}>
          <FieldsetSelect
            custom={true}
            data={reasons || []}
            label="Motivo"
            placeholder="Selecione um Motivo"
            name="name"
            handleChange={getReason}
            selected={state.reason}
            darkBackgroundColor={darkBackgroundColor}
          />
        </div>
      )}
    </div>
  );
};

export default UploadedMovie;
