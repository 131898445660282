/* eslint-disable no-mixed-operators */
import React, { useContext } from "react";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatepicker";
import Button from "../../../../components/Button/Button";
import SimpleButton from "../../../../components/SimpleButton/SimpleButton";
import BoxFooter from "../../../../components/BoxFooter/BoxFooter";
import Contracts from "../../Contracts/Contracts.js";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import AppContext from "../../../../context/appContext";
import InfoIcon from "../../../../components/VectorIcons/InfoIcon";
import FieldsetSelect from "../../../../components/FieldsetSelect/FieldsetSelect.js";

const FormAndFields = (props) => {
  const {
    action,
    handleSubmit,
    handleChangeDate,
    values,
    handleStartDates,
    handleMaxDates,
    chapterOrEpisode,
    planningData,
    handleValidate,
    customFooter = false,
    isChapter,
    isMaterial,
    nextValidArgumentEpisode,
    } = props;

  const appContext = useContext(AppContext);

  const handleContracts = (actionType) => {
    if (!isChapter && actionType !== 'attach') {
      const types = {
        create: Contracts.formAndFieldsPostObj,
        edit: Contracts.FormAndFieldsPutObjDramaCurta,
      };
      return types[actionType];
    }
    if (!isChapter && actionType === 'attach') {
      const types = {
        attach: Contracts.FormAndFieldsPutObjAttachDramaCurta
      };
      return types[actionType];
    }
    else {
      const types = {
        create: Contracts.formAndFieldsPostObj,
        edit: Contracts.FormAndFieldsPutObj,
        attach: [],
      };
      return types[actionType];
    }
  };

  let minVersionNumber;
  if (isMaterial) minVersionNumber = nextValidArgumentEpisode(values.attachmentTypeId);

  const fields = handleContracts(action.type);
  return (
    <form onSubmit={(e) => handleSubmit(e)} id="FormAndFields">
      {isMaterial && action.type === "create" && (
        <div className="customDatesContainer">
          <FieldsetSelect
            name="attachmentTypeId"
            label="Tipo de Material"
            placeholder="Selecione um material"
            handleChange={(e) => handleChangeDate(e.target.value, "attachmentTypeId")}
            selected={values.attachmentTypeId || ""}
            data={[
              { id: 1209, name: "Argumento" },
              { id: 1210, name: "Escaleta" },
              { id: 18738, name: "Roteiro" }
            ]}
          />

          <NumberInput
            label="Número da Versão"
            getVersion={(value) => handleChangeDate(value, "versionNumber")}
            maxNumber={100}
            minNumber={minVersionNumber}
            darkBackgroundColor={false}
            number={values.versionNumber}
          />
        </div>
      )}
      {chapterOrEpisode === "Capítulos" && action.type === "create" && (
        <div className="chaptersNumberContainer">
          <NumberInput
            label="Nº de Capítulos"
            getVersion={(value) =>
              handleChangeDate(value, "numberChaptersBlock")
            }
            maxNumber={10}
            darkBackgroundColor={false}
            number={values.numberChaptersBlock}
          />
        </div>
      )}
      {
        !!values["scheduleDelivery"] && !!values["scheduleRelease"] && (
          <div className="alertIconContainer">
            <InfoIcon color="#ffa810" size={24} />
            <p>
              <strong>Atenção!</strong> <br /> Data de liberação precisa ser
              posterior a data de entrega.
            </p>
          </div>
        )
      }

      <div className="customDatesContainer">
        {fields.map((item, index) => {
          return (
            <div className="customDate" key={index}>
              <CustomDatePicker
                label={item.label}
                date={values[item.key]}
                isScroll={true}
                startDate={handleStartDates[action.type][item.key]}
                maxDate={handleMaxDates[action.type][item.key]}
                handleChangeDate={(date) => handleChangeDate(date, item.key)}
              />
            </div>
          );
        })}
      </div>

      <div className="formAndFieldsFooter">
        <BoxFooter customFooter={customFooter} classBox="customBox">
          {isChapter && (
            <SimpleButton
              text="Cancelar"
              toValidate={true}
              isValidForm={true}
              classButton="buttonCancelNormal"
              typeButton="Salvar Bloco"
              dataQA="button-prospect-new-save"
              clickEvent={() => appContext.setCurrentStepBox("")}
            />
          )}
          <Button
            text={`Salvar ${action.type === 'attach' ? 'Anexo' : planningData.entity}`}
            toValidate={true}
            isValidForm={typeof handleValidate === 'function' ? handleValidate() : true}
            classButton="buttonGreen large"
            typeButton="submit"
          />
        </BoxFooter>
      </div>
    </form>
  );
};

export default FormAndFields;
