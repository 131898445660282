import React from "react";
import UploadInputDefault from "./UploadInputDefault";
import UploadInputDramaturgy from "./UploadInputDramaturgy";
import UploadInputMovie from "./UploadInputMovie";

const WrapperInput = (props) => {
  const { inputComponent, ...restProps } = props;
  const Wrapper = {
    Default: <UploadInputDefault {...restProps} />,
    Dramaturgy: <UploadInputDramaturgy {...restProps} />,
    Movie: <UploadInputMovie {...restProps} />,
  };

  return Wrapper[inputComponent];
};

export default WrapperInput;
