import React, { useState, useContext, useRef } from "react";
import "./styles/TableItem.scss";
import AppContext from "../../../../context/appContext";
import EditIcon from "../../../../components/VectorIcons/EditIcon";
import TypeCell from "../TypeCell/TypeCell";
import VersionsList from "../VersionsList/VersionsList";
import DeleteIcon from "../../../../components/VectorIcons/DeleteIcon";
import TableItemDates from "../TableItemDates/TableItemDates";
import TableItemDatesReleasedText from '../TableItemDatesReleasedText/TableItemDatesReleasedText';
import NoAttachments from "../NoAttachments/NoAttachments";
import ReleasedListAttachmentsController from "../../../ReleasedTexts/ReleasedListAttachments/ReleasedListAttachmentsController";
import ChaptersList from "../ChaptersList/ChaptersList";

const TableItem = (props) => {
  const {
    item,
    isChapter,
    isLastItem,
    isFirstItem,
    mainCellTitle,
    setStateAction,
    typeValues,
    getPlanning,
    handleFileDetails,
    handleEditFile,
    handleOpenReleaseTexts,
    handleDeleteChapter,
    type,
    projectTitle,
    isReleased,
    isMaterial,
  } = props;

  const [stateIsOpen, setStateIsOpen] = useState(false);

  const appContext = useContext(AppContext);

  const deleteButtonRef = useRef(null);

  const toggleIsOpen = (event) => {
    const deleteRef = deleteButtonRef.current;
    const clickedOnDeleteButton = deleteRef?.contains(event.target);

    if (clickedOnDeleteButton) return;
    setStateIsOpen((prevState) => !prevState);
  };

  const hasAttachments = item.attachments.some((chapter) => {
    return (
      chapter.attachment.fileId ||
      chapter.olderVersions.some((version) => !!version.fileId)
    );
  });

  const isEpisodeDeleteable = !isChapter && isLastItem && !hasAttachments;
  const episodeId = item?.attachments[0]?.attachment?.id;

  const handleEdit = (blockInfo) => {
    appContext.setCurrentStepBox("CreateAndUpdateBlockPlanning");
    setStateAction({ type: "edit", data: blockInfo });
  };

  const compileEpisodes = (attachments) => {
    let files = [];
    if (attachments) {
      attachments.forEach((episode) => {
        files = [
          ...files,
          episode.attachment,
          ...(episode.olderVersions ?? []),
        ];
      });
    }
    return files.filter((fileItem) => !!fileItem.fileId);
  };

  const compiledEpisodes = compileEpisodes(item.attachments);

  const handleDate = (date) => {
    if (date) return `${appContext.convertDatePtBr(date, 'AA')}`;
    if (isMaterial) return null;
    return <span className="datePlaceholder">{"-"}</span>;
  };

  const episodesDates = {
    scheduleDelivery: handleDate(item.scheduleDelivery),
    lastAttachmentReceiveDate: handleDate(item.lastAttachmentReceiveDate),
    scheduleRelease: handleDate(item.scheduleRelease),
    lastAttachmentReleaseDate: handleDate(item.lastAttachmentReleaseDate),
    plannedArtisticApproval: handleDate(item.plannedArtisticApproval),
    realizedArtisticApproval: handleDate(item.realizedArtisticApproval),
    plannedAuthorFeedback: handleDate(item.plannedAuthorFeedback),
    realizedAuthorFeedback: handleDate(item.realizedAuthorFeedback),
    plannedChannelFeedback: handleDate(item.plannedChannelFeedback),
    realizedChannelFeedback: handleDate(item.realizedChannelFeedback),
    plannedContentSight: handleDate(item.plannedContentSight),
    realizedContentSight: handleDate(item.realizedContentSight),
    plannedProdutionSight: handleDate(item.plannedProdutionSight),
    realizedProdutionSight: handleDate(item.realizedProdutionSight),
    rescheduledDelivery: handleDate(item.rescheduledDelivery),
    rescheduledRelease: handleDate(item.rescheduledRelease),
  }

  const listValidate = item.attachments.filter(
    (file) => !!file.attachment.fileId
  );

  return (
    <li className={`blockListItem ${stateIsOpen ? "open" : ""}`}>
      <div className="blockListItemHeaderNotClick">
        <div className="blockListHeader">
          <div className="blockListItemHeader" onClick={toggleIsOpen}>
            <TypeCell
              isChapter={isChapter}
              isMaterial={isMaterial}
              mainCellTitle={mainCellTitle}
              number={item.blockNumber || item.episodeNumber}
            />

            {(isChapter && item.numberChaptersBlock) || isReleased ?
              <span className="cell chaptersNumber mainCell">
                {isChapter && item.numberChaptersBlock}
                <span className="responsive">{isChapter && "Capítulos"}</span>
              </span>
              : ('')
            }

            {isReleased ? (
              <TableItemDatesReleasedText
                scheduleDelivery={episodesDates.scheduleDelivery}
                rescheduledDelivery={episodesDates.rescheduledDelivery}
                lastAttachmentReceiveDate={episodesDates.lastAttachmentReceiveDate}
                scheduleRelease={episodesDates.scheduleRelease}
                rescheduledRelease={episodesDates.rescheduledRelease}
                lastAttachmentReleaseDate={episodesDates.lastAttachmentReleaseDate}
                type={type}
              />
            ) : (
              <span className="tableItemDates">
                <TableItemDates
                  handleDate={handleDate}
                  dates={episodesDates}
                  type={type}
                  isChapter={isChapter}
                />
              </span>
            )
            }
          </div>

          <div className="blockActions">
            {type !== "channels" && (
              <div onClick={() => handleEdit(item)}>
                <EditIcon />
              </div>
            )}

            {/* {hasAttachments &&
            (blockIsReleased ? <ReleaseTextIconMarked /> : <ReleaseTextIcon />)} */}

            {isEpisodeDeleteable && type !== "channels" && (
              <div
                onClick={() => handleDeleteChapter(episodeId)}
                ref={deleteButtonRef}
              >
                <DeleteIcon />
              </div>
            )}
          </div>
        </div>
        <div className="mobile">
          <TableItemDates
            handleDate={handleDate}
            dates={episodesDates}
            type={type}
            isChapter={isChapter}
          />
        </div>
      </div>

      <div className="blockListItemContent">
        {isChapter && type === "projectSheet" && (
          <ChaptersList
            attachments={item.attachments}
            handleDate={handleDate}
            noAttachmentsMessage={typeValues.messages.noAttachmentsMessage}
            getPlanning={getPlanning}
            handleEditFile={handleEditFile}
            handleOpenReleaseTexts={handleOpenReleaseTexts}
            handleDeleteChapter={handleDeleteChapter}
            isLastTableItem={isLastItem}
            isFirstTableItem={isFirstItem}
          />
        )}

        {!isChapter && !!compiledEpisodes.length && type === "projectSheet" && (
          <VersionsList
            attachments={compiledEpisodes}
            handleDate={handleDate}
            getPlanning={getPlanning}
            handleFileDetails={handleFileDetails}
            handleEditFile={handleEditFile}
            handleOpenReleaseTexts={handleOpenReleaseTexts}
            isLastTableItem={isLastItem}
          />
        )}

        {type === "channels" && !!listValidate?.length && (
          <ReleasedListAttachmentsController
            files={listValidate}
            title={projectTitle}
            type={type}
            isChapter={isChapter}
          />
        )}

        {((!isChapter && !compiledEpisodes.length) ||
          (!listValidate?.length && type === "channels")) && (
            <NoAttachments message={typeValues.messages.noAttachmentsMessage} />
          )}
      </div>
    </li>
  );
};

export default TableItem;
