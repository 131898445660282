import React from "react";
import "./styles/ProjectReleasedTextTabs.scss";

const ProjectReleasedTextTabs = (props) => {
  const { contentType, currentTab, setCurrentTab, chapterOrEpisode, isMaterial } = props;

  return (
    <div className="tabs">
      {(contentType === "Dramaturgia" || contentType === "Variedades") && (
        <>
          {!isMaterial && (
            <span
              className={`tab ${
                currentTab === "Capítulos ou Episódios" ? "active" : ""
              }`}
              onClick={() =>
                setCurrentTab("Capítulos ou Episódios")
              }
            >
              {chapterOrEpisode === "Capítulos"
                ? "Capítulos/Episódios"
                : "Episódios"}
            </span>
          )}
          {isMaterial && (
            <span
              className={`tab ${
                currentTab === "Materiais" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("Materiais")}
            >
              Materiais
            </span>
          )}
          <span
            className={`tab ${currentTab === "Outros" ? "active" : ""}`}
            onClick={() => setCurrentTab("Outros")}
          >
            Outros Anexos
          </span>
        </>
      )}
    </div>
  );
};

export default ProjectReleasedTextTabs;
