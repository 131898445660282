import React from "react";
import "./styles/SimpleButton.scss";

const SimpleButton = (props) => {
  const {
    classButton,
    children,
    text,
    clickEvent,
    rippleEffect,
    isValidForm,
    toValidate,
    onBreadcrumb,
    dataQA,
    iconSide = "left",
    buttonColor,
    disabledMessage,
    appContext,
  } = props;
  const typeClass = `${classButton} button ${rippleEffect && "rippleButton"}`;
  const isActive = `${toValidate ? (isValidForm ? "active" : "disabled") : ""}`;
  const isDisabled = toValidate && !isValidForm;

  const _default = () => {
    return "default";
  };

  const handleMouseEnter = () => {
    if (isDisabled && disabledMessage) {
      appContext.sendMessage(disabledMessage, "warning");
    }
  };

  return (
    <div
      className="buttonWrapper" 
      onMouseEnter={handleMouseEnter}
    >
      <div
        style={{ backgroundColor: buttonColor }}
        className={`${typeClass} ${isActive} simpleButton`}
        onClick={!!clickEvent ? clickEvent : () => _default()}
        data-qa={`${dataQA}`}
      >
        {iconSide === "left" ? (
          <>
            {children}
            <span className={`${onBreadcrumb && "hideOnBreadcrumb"}`}>
              {text}
            </span>
          </>
        ) : (
          <>
            <span className={`${onBreadcrumb && "hideOnBreadcrumb"}`}>
              {text}
            </span>
            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default SimpleButton;
