import React, { useContext, useState } from "react";
import AttachmentIcon from "../../../components/VectorIcons/AttachmentIcon";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import AppContext from "../../../context/appContext";
import TableListFiles from "../ListFiles/TableListFiles";
import {
  deleteService,
  postService,
  putService,
} from "../../../services/script-api";
import SheetContext from "../../../context/sheetContext";
import ConfirmationBox from "../../../components/ConfirmationBox/ConfirmationBox";
import UploadSection from "../../../components/UploadSection/UploadSection";
import UploadService from "../../../components/UploadService/UploadService";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import SimpleScriptBox from "../SimpleScriptBox/SimpleScriptBox";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import EditFile from "../EditFile/EditFile";

const ContentAttachments = (props) => {
  const {
    data,
    idProject,
    type,
    projectType,
    havePermission,
    condition,
    isExternal,
    isMaterial,
  } = props;

  const appContext = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  const [stateConfirmationBox, setStateConfirmationBox] = useState(false);
  const [stateFiles, setStateFiles] = useState([]);
  const [stateEditFileBox, setStateEditFileBox] = useState({
    open: false,
    currentFile: "",
  });

  const headerList = [
    { label: "Tipo", className: "" },
    { label: "Versao", className: "large" },
    { label: "Artigo", className: "editItem" },
    { label: "RECEBIDO EM", className: "editItem" },
  ];

  const filteredData = isMaterial
    ? data.filter(
        (item) =>
          ![1209, 1210, 18738].includes(item?.attachmentType?.id)
      )
    : data;

  const validAttachmentTypes = appContext.currentOriginFileType?.result?.filter(
    (item) => item.name !== "Capítulo/Episódio"
  );

  const handleDeleteFile = (file) => {
    deleteService("contents/attachments", file.id).then(() => {
      appContext.sendMessage(`Arquivo ${file.fileName} deletado com sucesso`);
      setStateConfirmationBox({
        ...stateConfirmationBox,
        open: false,
      });
      sheetContext._getProjectSheet(idProject, type);
    });
  };
  const handleConfirmationDeleteFile = (item) => {
    setStateConfirmationBox({
      ...stateConfirmationBox,
      open: true,
      currentFile: item,
    });
  };

  const closedBox = () => {
    setStateConfirmationBox({
      ...stateConfirmationBox,
      open: false,
    });
  };

  const getDuplicateFiles = (files) => {
    let filesName = "";
    files.map(
      (e) => (filesName += `<p>${e[0].originalName} ja foi selecionado!</p>`)
    );
    appContext.sendMessage(filesName, "error");
  };

  const getFiles = (files) => {
    setStateFiles({
      ...stateFiles,
      files,
    });
  };

  const uploadFilesIsValid = () => {
    if (!!stateFiles.files && stateFiles.files.length > 0) {
      return (
        stateFiles.files.every((file) => !!file.attachmentTypeId) &&
        stateFiles.files.every((stateFile) => stateFile.isSent === true)
      );
    }
  };

  const handleEditFile = (item) => {
    setStateEditFileBox({
      ...stateEditFileBox,
      open: true,
      currentFile: item,
    });
  };

  const sendFile = () => {
    appContext.setShowGlassLoading(true);
  
    const mappedAttachmentTypeId = {
      1209: 18870,
      1210: 18871,
      18738: 18872,
    };
  
    const obj = stateFiles.files.map((file) => ({
      contentId: idProject,
      fileId: file._meta.tempFileName,
      fileName: file.originalName,
      version: file.version,
      receivedAt:
        appContext.transformDateToUTC(file.receiveAt) ||
        file.receiveAt ||
        null,
      releaseDate: null,
      attachmentTypeId: mappedAttachmentTypeId[file.attachmentTypeId] || file.attachmentTypeId,
      blockNumber: file.blockNumber,
      chapterNumber: file.chapterNumber,
      genresIds: [0],
      spacesIds: [0],
      release: 0,
      contentOrigns: [
        {
          contentOriginId: 0,
          contentOriginDescriptions: ["string"],
        },
      ],
      narratives: [0],
    }));

    postService("contents/attachments", obj)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Arquivo cadastrado com sucesso!");
        appContext.setCurrentStepBox("closed");
        sheetContext._getProjectSheet(idProject, type);
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao cadastrar o arquivo!", "error");
        appContext.setCurrentStepBox("closed");
        sheetContext._getProjectSheet(idProject, type);
      });
  };

  const sendEditFile = (obj) => {
    appContext.setShowGlassLoading(true);
    putService("contents/attachments", obj)
      .then(() => {
        setStateEditFileBox({
          ...stateEditFileBox,
          open: false,
        });
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          `Arquivo ${stateEditFileBox.currentFile.fileName} atualizado com sucesso`
        );
        sheetContext._getProjectSheet(idProject, type);
      })
      .catch(() => {
        appContext.sendMessage(
          `Erro ao tentar atualizar ${stateEditFileBox.currentFile.fileName}!`,
          "error"
        );
        appContext.setShowGlassLoading(false);
      });
  };

  const closedBoxEdit = () => {
    setStateEditFileBox({
      ...stateEditFileBox,
      open: false,
    });
  };

  return (
    <div>
      {appContext.current.step === "editAttachment" && (
        <ScriptBox
          open={appContext.current.step === "editAttachment"}
          icon={<AttachmentIcon color="#FFF" />}
          removePadding={true}
          title="Anexos"
          size="large"
        >
          <TableListFiles
            headerList={headerList}
            handleEditFile={handleEditFile}
            handleConfirmationDeleteFile={handleConfirmationDeleteFile}
            renderCreatedAt={true}
            data={filteredData}
            type={type === "literaryworks" ? type : "dramaturgy"}
            projectType={projectType}
            condition={condition}
            havePermission={havePermission}
            reload={() => sheetContext._getProjectSheet(idProject, type)}
            isExternal={isExternal}
          />
        </ScriptBox>
      )}

      {appContext.current.step === "uploadAttachment" && (
        <ScriptBox
          open={appContext.current.step === "uploadAttachment"}
          title="Novo Anexo"
          icon={<AttachmentIcon color="#FFF" />}
          marginBottom={true}
        >
          <div className="scriptBoxUpload">
            <UploadSection>
              <UploadService
                thesaurusFileType={validAttachmentTypes}
                handleOnComplete={getFiles}
                getDuplicateFiles={getDuplicateFiles}
              />
            </UploadSection>
          </div>

          <BoxFooter>
            <Button
              text="Salvar"
              toValidate={true}
              isValidForm={uploadFilesIsValid()}
              classButton="buttonGreen large"
              typeButton="submit"
              dataQA="button-prospect-edit"
              clickEvent={sendFile}
            />
          </BoxFooter>
        </ScriptBox>
      )}

      {stateEditFileBox.open && (
        <SimpleScriptBox
          open={stateEditFileBox.open}
          toggleScriptBox={() => closedBoxEdit()}
          title={stateEditFileBox.currentFile.fileName}
          icon={<EditIcon color="#FFF" />}
          removePadding={true}
        >
          <EditFile
            fileName={stateEditFileBox.currentFile.fileName}
            versionNumber={parseInt(1)}
            thesaurusFileType={appContext.currentOriginFileType.result}
            handleChangeDate={() => {}}
            value={1}
            file={stateEditFileBox.currentFile}
            sendFileEdit={sendEditFile}
            condition={condition}
          />
        </SimpleScriptBox>
      )}

      {stateConfirmationBox.open && (
        <ConfirmationBox
          open={stateConfirmationBox.open}
          textButton="Sim, excluir"
          closedBox={closedBox}
          handleEvent={() => handleDeleteFile(stateConfirmationBox.currentFile)}
          title="Tem certeza?"
          text="Este anexo será excluído permanentemente do sistema e essa ação não poderá ser desfeita"
        />
      )}
    </div>
  );
};

export default ContentAttachments;
