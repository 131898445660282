import React from "react";

const FileView = (props) => {
  const { color = "#0098CD", size = 20 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6111 0C12.8911 0 13.1595 0.105357 13.3575 0.292893L19.6908 6.29289C19.8888 6.48043 20 6.73478 20 7V19C20 20.6569 18.5822 22 16.8333 22H12.0137L14.1248 20H16.8333C17.3747 20 17.8208 19.614 17.8818 19.1166L17.8889 19V7.415L12.1731 2H4.16667C3.5837 2 3.11111 2.44772 3.11111 3V9.22L1 11.221V3C1 1.40232 2.3183 0.0963391 3.9806 0.00509269L4.16667 0H12.6111Z"
        fill={color}
      />
      <path
        d="M12.6113 1V7H18.9447"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.38921 20C5.47438 20 3.11144 17.7614 3.11144 15C3.11144 12.2386 5.47438 10 8.38921 10C11.3041 10 13.667 12.2386 13.667 15C13.667 17.7614 11.3041 20 8.38921 20Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00033 22L4.16699 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileView;
