import axios from "axios";
import qs from "qs";
import {
  getIdToken,
  getRefreshToken,
  handleLogin,
  logout,
} from "../lib/login-store";
import { refreshAuth } from "../../services/auth-api";
import { API_KEY, BASE_URL } from "../constants/env";

let isRefreshing = false;
let subscribers = [];

const client = axios.create({
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "repeat" }),
});

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map((cb) => cb(token));
  subscribers = [];
}

client.interceptors.request.use(
  (config) => {
    if (config.url.startsWith(BASE_URL)) {
      config.headers["Authorization"] = getIdToken();

      config.headers["x-api-key"] = API_KEY;

    } else {
      delete config.headers.common["Authorization"];
      delete config.headers.common["x-api-key"];
      delete config.headers.common["x-p"];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.error("Request Failed:", error.config);

    const responseStatus = error.response ? error.response.status : 0;
    const originalRequest = error.config;

    if (responseStatus === 401) {
      console.log("Unauthorized Access, sending refresh token");

      if (!isRefreshing) {
        isRefreshing = true;

        return refreshAuth(getRefreshToken())
          .then((response) => {
            if (response) {
              return response;
            } else {
              throw new Error();
            }
          })
          .then((data) => {
            console.log("Got Tokens by Update Token");
            handleLogin(data);
            isRefreshing = false;
            onRefreshed(data.accessToken);
            originalRequest.headers["Authorization"] = data.accessToken;
            return client(originalRequest);
          })
          .catch((e) => {
            logout();
            window.location = "/auth";
            return Promise.reject(e);
          });
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(client(originalRequest));
        });
      });
    } else if (responseStatus === 403) {
      console.log("Forbidden Access");

      logout();
    } else if (responseStatus >= 400) {
      if (responseStatus !== 403) {
        console.log("Redirecting to ErrorPage");
        // window.location = "/erro";
      } else {
        console.error("Error Message:", error.message);
      }
    } else {
      console.error("Ocorreu um erro ao processar sua solicitação");
    }

    return Promise.reject(error);
  }
);

export default client;
