import React, { useContext } from "react";
import AppContext from "../../context/appContext";
import "./style/Alerts.scss";
import CheckIcon from "../VectorIcons/CheckIcon";
import CloseIcon from "../VectorIcons/CloseIcon";
import WarningIcon from "../VectorIcons/WarningIcon";

const Alerts = (props) => {
  const appContext = useContext(AppContext);
  const closeAlert = () => {
    appContext.closeMessage();
  };

  const { message, type } = props;

  return (
    <div className="alertsContainer">
      <div className={`alert ${type}`}>
        {appContext.message.type === "success" && (
          <div className="successIcon">
            <CheckIcon color="#43444e" size={16} />
          </div>
        )}

        {appContext.message.type === "error" && (
          <div className="errorIcon">
            <CloseIcon color="#43444e" size={12} strokeWidth={4} />
          </div>
        )}

        {appContext.message.type === "warning" && (
          <div className="warningIcon">
            <WarningIcon color="#FDCD02" size={12} strokeWidth={4} />
          </div>
        )}

        <div
          className="messageContainer"
          dangerouslySetInnerHTML={{ __html: message }}
        />

        <div className="closeIcon" onClick={() => closeAlert()}>
          <CloseIcon color="#FFF" size={18} />
        </div>
      </div>
    </div>
  );
};

export default Alerts;
