import React from "react";
import NextArrowIcon from "../../../../components/VectorIcons/NextArrowIcon";

const TypeCell = (props) => {
  const { isChapter, mainCellTitle, number, isMaterial } = props;

  return (
    <>
      {isMaterial && (
        <span className="episode mainCell">
        <div className="triangle" />
        {`${mainCellTitle}`}
      </span>
      )}

      {isChapter && (
        <span className="blockNumber mainCell">
          <NextArrowIcon color="#000" />
          {`${mainCellTitle} ${number}`}
        </span>
      )}

      {!isChapter && !isMaterial && (
        <span className="episode mainCell">
          <div className="triangle" />
          {`${mainCellTitle} ${number}`}
        </span>
      )}
    </>
  );
};

export default TypeCell;
