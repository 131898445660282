import React, { useContext } from "react";
import DownloadIcon from "../../../components/VectorIcons/DownloadIcon";
import AttachmentsContext from "../../../context/attachmentsContext";
import AppContext from "../../../context/appContext";
import CheckBox from "../../Conflicts/CheckBox/CheckBox";
import FileView from "../../../components/VectorIcons/FileView";

const ListAttachments = (props) => {
  const {
    index,
    file,
    handleChangeFiles,
    title,
    parentIndex,
    type,
    isChapter,
    isMaterial,
  } = props;

  const appContext = useContext(AppContext);
  const attachmentContext = useContext(AttachmentsContext);

  const canDownload = {
    channels: !!file.release && !!file.fileId,
    textReleased: !!file.fileId,
  };

  return (
    <div className="attachmentItem" key={index}>
      <ul
        className={
          type === "channels" ? "releasedVersionItem" : "mainVersionItem"
        }
      >
        <li className="type">
          {!!file.release && (
            <div
              onClick={() =>
                handleChangeFiles(
                  file.fileId,
                  file.fileName,
                  title,
                  file,
                  true,
                  parentIndex,
                  index
                )
              }
            >
              <CheckBox isActive={file.isActive} />
            </div>
          )}
          {!file.release && <div style={{ width: 28 }} />}
          <span className="typeName">
          {type !== "channels"
            ? file.attachmentType?.name
            : isMaterial
            ? file.attachmentType?.name
            : !!isChapter
            ? "Capítulo"
            : "Episódio"}
            {` ${
              file.attachmentType?.name === "Capítulo/Episódio" ||
              file.attachmentType?.name === "Episódio"
                ? file.chapterNumber
                : ""
            }`}
          </span>

          {(file.attachmentType?.name === "Capítulo/Episódio" ||
            file.attachmentType?.name === "Episódio") &&
            type !== "channels" && (
              <span className="blockNumber">
                {" "}
                Bloco {file.blockNumber > 0 && file.blockNumber}
              </span>
            )}
        </li>

        <li
          className={`version ${
            !!file.version && file.version.length > 0 ? "hasVersions" : ""
          }`}
        >
          <span className="versionNumber">
            {!!file.version ? `${file.version}ª` : "-"}
          </span>
          <span className="versionText">versão</span>
          {!!file.version && file.version.length > 0 && (
            <div className="itemArrow" />
          )}
        </li>

        <li className="fileComment">
          <p className="fileNameAttachment">
            <strong>{file?.fileName}</strong>
          </p>
          {!file.comment && type === "channels" ? "-" : file.comment}
        </li>

        <li className="receivedAt">
          {file.releaseDate &&
            appContext.formatDate(appContext.convertDatePtBr(file.releaseDate))}
        </li>

        {canDownload[type] && (
          <li className="download">
            {file.fileName?.slice(-3) !== "fdx" && file.fileName !== null && (
              <div
                className="actionItem"
                onClick={() => {
                  appContext.setCurrentFileUrl(file.fileId, file.fileName);
                  console.log(file.fileId);
                  console.log(file.fileName);
                  appContext.setCurrentStepBox("ViewerFileBox");
                }}
              >
                <FileView />
              </div>
            )}
            <div
              className="actionItem"
              onClick={() =>
                attachmentContext.getUrlS3(file.fileId, file.fileName)
              }
            >
              <DownloadIcon />
            </div>
          </li>
        )}
        
      </ul>
    </div>
  );
};

export default ListAttachments;
