export const handleDramaturgyTypeByFormat = (format) => {
  const dramaturgyLong = ["Novela", "Supersérie"];
  const dramaturgyShort = [
    "Curta",
    "Especial",
    "Filme/Telefilme",
    "Minissérie",
    "Podcast",
    "Quadro",
    "Série",
  ];
  if (format === "Filme") return "Filme";
  if (dramaturgyLong.includes(format)) return "Dramaturgia Longa";
  if (dramaturgyShort.includes(format)) return "Dramaturgia Curta";
};
