import React from "react";
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import HeaderWithIconType from "../../../components/HeaderWithIconType/HeaderWithIconType";
import { returnFictionAndNotFictionLabel } from "../../../shared/utils/returnFictionAndNotFictionLabel";

const ContentHeader = (props) => {
  const {
    list,
    stateCurrentTab,
    setStateCurrentTab,
    isExternal,
    chapterOrEpisode,
  } = props;

  const creatorsOrSuggester = list.creators || list.suggestedBy;
  let history = useHistory();

  const handleLabelByExternalOrNot = (type) =>
    isExternal ? returnFictionAndNotFictionLabel(type) : type;

  const setTextLiteraryWorks = (type) =>
    !!type ? handleLabelByExternalOrNot(type) : "Obra Literária";

  return (
    <Breadcrumb>
      <>
        <div className="projectHeader">
          <div className="projectTitle">
            <HeaderWithIconType
              title={list.title || ""}
              type={list.projectType || "obra-literaria"}
            />
          </div>

          <div className="projectSubtitle">
            <span className="projectCreator">
              {creatorsOrSuggester.map((item, index) => {
                return (
                  <span className="infoData" key={index}>
                    {item.name}
                    {index + 1 < creatorsOrSuggester.length ? (
                      <span className="creatorSeparator">|</span>
                    ) : (
                      ""
                    )}
                  </span>
                );
              })}
            </span>
            <div className="projectType">
              <span>{setTextLiteraryWorks(list.projectType)}</span>
              <div className="dotSeparator" />
              <span>{list.format.name || ""}</span>
              {!!list.soapOperaSchedule && list.format.name === "Novela" && (
                <>
                  <div className="dotSeparator" />
                  <span className="soapOperaSchedule">
                    {!!list.soapOperaSchedule
                      ? list.soapOperaSchedule.value
                      : ""}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="projectSheetTabs">
          {list.projectType === "Dramaturgia" && !isExternal && (
            <>
              <span
                className={`sheetTab ${
                  stateCurrentTab === "projectSheet" ? "active" : ""
                }`}
                onClick={() => setStateCurrentTab("projectSheet")}
              >
                Ficha do Projeto
              </span>
              <span
                className={`sheetTab ${
                  stateCurrentTab === "attachments" ? "active" : ""
                }`}
                onClick={() => setStateCurrentTab("attachments")}
              >
                {chapterOrEpisode}
              </span>
            </>
          )}
          {list.projectType === "Variedades" && !isExternal && (
            <>
              <span
                className={`sheetTab ${
                  stateCurrentTab === "projectSheet" ? "active" : ""
                }`}
                onClick={() => setStateCurrentTab("projectSheet")}
              >
                Ficha do Projeto
              </span>
              <span
                className={`sheetTab ${
                  stateCurrentTab === "attachments" ? "active" : ""
                }`}
                onClick={() => setStateCurrentTab("attachments")}
              >
                Episódios
              </span>
            </>
          )}
          {isExternal && (
            <>
              <span
                className={`sheetTab ${
                  stateCurrentTab === "projectSheet" ? "active" : ""
                }`}
                onClick={() => setStateCurrentTab("projectSheet")}
              >
                Ficha do Projeto
              </span>
              <span
                className={`sheetTab ${
                  stateCurrentTab === "producerInfo" ? "active" : ""
                }`}
                onClick={() => setStateCurrentTab("producerInfo")}
              >
                Produtoras
              </span>
            </>
          )}
          <div className="backListProject">
            {isExternal ? (
              <Link to="/projetos-externos" className="goBack">
                <span>{`« Voltar`}</span>
                <span className="goBackResponsiveText">
                  para a lista de projetos
                </span>
              </Link>
            ) : (
              <div
                onClick={() => history.goBack()}
                className={`goBack ${
                  list.projectType !== "Dramaturgia" && "goBackVarities"
                }`}
              >
                <span>{`« Voltar`}</span>
                <span className="goBackResponsiveText">
                  para a página anterior
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    </Breadcrumb>
  );
};

export default ContentHeader;
